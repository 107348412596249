

const types = {
    postType:[
        {label: "游戏讨论", value: 1},
        {label: "Mod制作", value: 2},
        {label: "攻略心得", value: 3},
        {label: "反馈建议", value: 4},
    ],
    workOrderType:[
        {value: "game", label: "游戏问题"},
        {value: "login", label: "登录问题"},
        {value: "payment", label: "支付问题"},
        {value: "account", label: "账号问题"},
        {value: "other", label: "其他"},
    ],
    workOrderStatusType:[
        {value: 1, label: "开启中"},
        {value: 2, label: "处理中"},
        {value: 3, label: "已关闭"},

    ]

}


export default types;


export function useTypes(){
    return types;
}


function getTypes(){

}


export function getTypeLabel(types, val){
    for (let type of types) {
        if(type.value === val){
            return type.label;
        }
    }

    return "";
}

export function getPostType(post){
    for (let type of types.postType) {
        if(type.value === post?.sectionId){
            return type.label;
        }
    }

    return "";
}

export function getWorkOrderType(data){
    for (let type of types.workOrderType) {
        if(type.value === data?.type){
            return type.label;
        }
    }

    return "";
}