import request from "../utils/request";


export function listWorkOrderApi(params){
    return request({
        url: `/workOrder/list`,
        method: "get",
        params
    })
}

export function listExamineWorkOrderApi(params){
    return request({
        url: `/workOrder/examine/list`,
        method: "get",
        params
    })
}



export function listCommonProblemApi(params){
    return request({
        url: `/commonProblem/list`,
        method: "get",
        params
    })
}

export function addCommonProblemApi(type, title, url, shortDesc, index){
    return request({
        url: `/commonProblem/add`,
        method: "post",
        data:{
            type,
            title,
            url,
            shortDesc,
            index
        }
    })
}

export function getCommonProblemTopApi(){
    return request({
        url: `/commonProblem/tops`,
        method: "get",
    })
}



export function getWorkOrderApi(id){
    return request({
        url: `/workOrder/details/${id}`,
        method: "get",
    })
}

export function addWorkOrderApi(title, type, content){
    return request({
        url: `/workOrder/add`,
        method: "post",
        data: {
            title,
            type,
            content
        },
    })
}

export function workOrderStatisticsApi(){
    return request({
        url: `/workOrder/statistics/`,
        method: "get",
    })
}

export function delWorkOrderApi(ids){
    return request({
        url: `/workOrder/${ids}`,
        method: "delete",
    })
}

export function closeWorkOrderApi(ids){
    return request({
        url: `/workOrder/close/${ids}`,
        method: "post",
    })
}

export function addWorkOrderCommentApi(workId, content){
    return request({
        url: `/workOrderComment/add`,
        method: "post",
        data: {
            workId,
            content
        },
    })
}


export function listWorkOrderCommentApi(params){
    return request({
        url: `/workOrderComment/list`,
        method: "get",
        params
    })
}