import "./index.css"
import {Button, Card, Col, List, Radio, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {listNewsApi} from "../../apis/news";
import {dateUtcFormat} from "../../utils/TimeUtil";




export default function Index() {
    const [newsType, setNewsType] = useState("notice")
    const [newsLoading, setNewsLoading] = useState(false)
    const [newsTableData, setNewsTableData] = useState({
        total: 0,
        rows: [],
    })

    useEffect(()=>{
        document.addEventListener('DOMContentLoaded', (event) => {
            const menuToggle = document.querySelector('.menu-toggle');
            const navMenu = document.querySelector('.nav ul');
            menuToggle.addEventListener('click', () => {
                navMenu.classList.toggle('show');
            });

            // 移除加载动画
            window.addEventListener('load', () => {
                const loader = document.querySelector('.loader');
                loader.style.display = 'none';
            });
        });
    }, [])

    useEffect(()=>{
        setNewsLoading(true)

        listNewsApi({
            pageSize: 10,
            page: 1,
            type: newsType
        }).then(res=>{
            if(res.code===200){
                setNewsTableData(res.data)
            }

            setNewsLoading(false)
        }).catch(e=>{
            setNewsLoading(false)
        })


    }, [newsType])


    const onNewsTypeChange = (e)=>{
        setNewsType(e.target.value)
    }

    const title = <Row justify={"space-between"}>
        <Col className={"news-card-title"}>
            新闻推荐
        </Col>
        <Col>
            <Radio.Group
                value={newsType}
                buttonStyle="solid"
                onChange={onNewsTypeChange}
            >
                <Radio.Button value="notice">公告</Radio.Button>
                <Radio.Button value="news">新闻</Radio.Button>
                <Radio.Button value="activity">活动</Radio.Button>
            </Radio.Group>
        </Col>
    </Row>

    return <div className={"home"}>
        <div className="background"></div>
        <div className="content" style={{height: "max-content"}}>
            <img src="/forum/static/images/icon.png"
                 alt="PocketDestiny Logo" className="logo"/>

            <h1 style={{color: "white"}}>欢迎来到 PocketDestiny</h1>
            <p>探索无限可能的口袋世界。收集、训练、对战，成为最强大的训练师！</p>

            <Spin spinning={newsLoading}>
                <Card title={title} className={"news-card"}>
                    <NewsList data={newsTableData.rows} />

                    {newsTableData.total > 10 && <Button style={{margin: "auto", marginTop: 8}}>查看更多</Button>}
                </Card>
            </Spin>

        </div>


    </div>
}


function NewsList(props) {
    const {data} = props

    const [initLoading, setInitLoading] = useState(false);

    return <List
        loading={initLoading}
        itemLayout="horizontal"
        size={"small"}
        dataSource={data}
        renderItem={(item) => (
            <List.Item key={item.id} className={"news-item"}>
                <a  href={item.url} style={{display: "inline-block", width: "100%"}} >
                    <Row justify={"space-between"}>
                        <Col>
                            <h3 className={"news-title"} >{item.title}</h3>
                        </Col>
                        <Col><p className={"news-date"}>{dateUtcFormat(item.createAt, "YYYY-MM-DD")}</p></Col>
                    </Row>
                </a>
            </List.Item>
        )}/>
}