import "./index.css"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import  {Quill} from "react-quill";

import "react-quill/dist/quill.snow.css"
import {Button, Divider, Input, Space, Spin} from "antd";
import {addPostCommentApi, delPostApi, getPostDetailsApi, likePostApi, listPostCommentApi} from "../../../../apis/post";
import {dateUtcFormat} from "../../../../utils/TimeUtil";
import {error_notice, success_notice} from "../../../../utils/noticeUtils";
import {connect} from "react-redux";
import {LikeOutlined, StarOutlined} from "@ant-design/icons";
import {addFavoritesApi} from "../../../../apis/favorites";
import {addImgHostPath, getServerImgPath} from "../../../../utils/ImageUtil";
import {authIsAdmin, authIsManage} from "../../../../utils/authUtil";
import AddNewsModal from "./AddNewsModal";
import {showModalAction} from "../../../../redux/action/modalAction";
import {addNewsApi} from "../../../../apis/news";
import AddProblemModal from "./AddProblemModal";
import {addCommonProblemApi} from "../../../../apis/workOrder";



function PostInfo(props) {
    const {userInfo} = props;

    const navigate = useNavigate();
    const [loadingTitle, setLoadingTitle] = useState(null);
    const [post, setPost] = useState({});
    const [comment, setComment] = useState("");
    const [replyComment, setReplyComment] = useState(null);

    const [commentTableData, setCommentTableData] = useState({
        rows:[],
        total: 0,
        page:1,
        pageSize: 20,
    });

    const {id} = useParams()


    useEffect(()=>{
        setLoadingTitle(" ")
        getPostDetailsApi(id).then(res=>{
            if(res.code === 200){
                res.data.content = addImgHostPath(res.data.content)
                setPost(res.data)
            }

            setLoadingTitle(null)
        })

        queryComment(1)

    }, [id])


    const queryComment = (page=1, add=false)=>{
        setLoadingTitle("加载中...")

        listPostCommentApi({
            page: page,
            pageSize: 10,
            postId: id
        }).then(res=>{

            if(res.code === 200){
                let rows = []

                if(add){
                    res.data.rows = [...commentTableData.rows, ...res.data.rows]
                }

                res.data.page = parseInt(res.data.page)
                setCommentTableData(res.data)
            }

            setLoadingTitle(null)
        }).catch(e=>{
            setLoadingTitle(null)
        })
    }


    const onSubmitComment = () => {
        if(!comment){
            error_notice("无法提交空的评论!")
            return
        }

        // 清空上次评论信息
        setComment("")
        setReplyComment(null)

        addPostCommentApi(id, comment, replyComment?.id).then(res=>{
            if(res.code === 200){
                success_notice("评论成功")
                queryComment()
            }

        }).catch(e=>{
            error_notice("评论失败")
        })

    }

    const onClickFavorites = ()=>{

        addFavoritesApi(id, post.title).then(res=>{
            if(res.code === 200){
                success_notice("收藏成功!")
            }

        }).catch(e=>{
            error_notice("收藏失败")
        })

    }

    const onClickLike = ()=>{

        likePostApi(id).then(res=>{
            if(res.code === 200){
                success_notice("点赞成功!")
            }

        }).catch(e=>{
            error_notice("点赞失败")
        })

    }


    const commentLoadMore =
        !loadingTitle && commentTableData.page*commentTableData.pageSize < commentTableData.total ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button type={"primary"} size={"large"} onClick={()=>{
                    queryComment(commentTableData.page+1, true)
                }}>更多评论</Button>
            </div>
        ) : null;


    const onReplyComment = (comment) => {
        setReplyComment(comment)
    }

    function getCommentById(id){
       return  commentTableData.rows.find(item=>item.id === id)
    }


    return <Spin spinning={loadingTitle} tip={loadingTitle}>
        <div className="post-details-page">
            <div className="post-details-page-container">
                <main className="main-content">
                    <article>
                        <header className="article-header">
                            <h1 className="article-title">{post?.title}</h1>

                            <AdminOp post={post}/>

                            <div className="article-meta">
                                <span>作者：{post.userInfo?.nickname}</span>
                                <span>发布时间：{post.createAt && dateUtcFormat(post.createAt)}</span>
                                <span>阅读量：{post.view}</span>
                                <span>点赞：{post.likes}</span>
                            </div>
                        </header>
                        <div className="article-content" dangerouslySetInnerHTML={{__html: post?.content || ""}}>

                        </div>
                    </article>

                    <section className="comment-section">
                        <h2>评论区</h2>
                        {replyComment && <div style={{marginBottom: 5}}>
                            <div>回复{replyComment?.userInfo?.nickname}</div>
                            <div>{replyComment?.content}</div>
                        </div>}
                        {
                            userInfo?.username ? <form className="comment-form">
                                <Input.TextArea showCount  maxLength={50} value={comment}  onChange={(e) => {
                                    setComment(e.target.value)
                                }} placeholder="发表你的评论..."></Input.TextArea>
                                <Button type={"primary"} style={{marginTop: 5}}
                                        onClick={onSubmitComment} >提交评论</Button>
                            </form> : <div>
                                <label style={{color: "#7f8c8d"}}>登录后方可评论 </label>
                                <a href="/forum/login" >立即登录</a>
                            </div>
                        }


                        <div className="comments">
                            {commentTableData.rows.map(comment => {
                                return <div className="comment" id={comment.id}>
                                    <img src={getServerImgPath(comment.userInfo?.headUrl)} alt=""
                                         className="comment-avatar"/>
                                    <div className="comment-content">
                                        <Space>
                                            <div className="comment-author">{comment.userInfo?.nickname+" "}
                                                {comment.replyId && <>
                                                    @
                                                    {" "+getCommentById(comment.replyId)?.userInfo?.nickname}
                                                </>}
                                            </div>

                                            <Button size={"small"} style={{fontSize: 12}} onClick={()=>{
                                                onReplyComment(comment)
                                            }}>回复</Button>
                                        </Space>

                                        <p> {comment.content} </p>
                                        <div className="comment-date">{dateUtcFormat(comment.createAt)}</div>
                                    </div>
                                </div>
                            })}

                            {commentLoadMore}
                        </div>
                    </section>
                </main>

                <aside className="sidebar">

                    <div className="sidebar-widget">
                        <h3 className="widget-title">关于作者</h3>
                        <Space align={"center"}>
                            <img src={getServerImgPath(post.userInfo?.headUrl)} alt="用户头像" className="post-avatar"/>
                            <h2 style={{marginBottom: 0}}>{post.userInfo?.nickname}</h2>
                        </Space>

                        <Divider style={{margin: 12}}/>

                        {
                            post.userInfo?.tags && <>
                                {post.userInfo.tags.split(",").map(tag=> {
                                    return <span className="profile-tag">{tag}</span>
                                })}
                            </>
                        }


                        <p style={{marginTop: 8}}>{post.userInfo?.profile}</p>
                    </div>
                </aside>
            </div>

            <div className={"post-op"}>
                <Space direction={"vertical"}>
                    <Button shape="circle" size="large" icon={<StarOutlined />} onClick={onClickFavorites}/>
                    <Button shape="circle" size="large" icon={<LikeOutlined />} onClick={onClickLike}/>
                </Space>
            </div>

        </div>

    </Spin>

}


function AdminOp(props){
    const {post} = props;

    const onAddNewsOk = (news) => {
      addNewsApi(news.type, news.title, news.url).then(res=>{
          if(res.code===200){
              success_notice("添加新闻成功！")
          }
      })
    }

    const onAddProblemOk = (data) => {
        addCommonProblemApi(data.type, data.title, data.url, data.shortDesc, data.index).then(res=>{
            if(res.code===200){
                success_notice("添加常见问题成功！")
            }
        })
    }


    return<>
        {authIsAdmin() &&
            <div>
                <Space>
                    <Button onClick={()=>{showModalAction("AddNewsModal", {
                        type: "notice",
                        url: `/forum/post/details/${post.id}`,
                        title: post.title,
                    })}}>添加为新闻</Button>

                    <Button onClick={()=>{showModalAction("AddProblemModal", {
                        type: "notice",
                        url: `/forum/post/details/${post.id}`,
                        title: post.title,
                        shortDesc: post.title,
                        index: 1000
                    })}}>添加为常见问题</Button>

                    <Button onClick={()=>{
                        delPostApi(post.id).then(res=>{
                            if(res.code===200){
                                success_notice("删除成功!")
                            }
                        })
                    }}>删除帖子</Button>

                </Space>
                <AddNewsModal onOk={onAddNewsOk}/>
                <AddProblemModal onOk={onAddProblemOk}/>

                <Divider/>
            </div>
        }
    </>

}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostInfo)

// 自定义的图片处理模块
const ImageBlot = Quill.import('blots/embed');

class Image extends ImageBlot {
    static create(value) {
        let node = super.create();
        node.setAttribute('src', value);
        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}

Image.blotName = 'image';
Image.tagName = 'img';
Quill.register(Image);


