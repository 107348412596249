import {useEffect, useState} from "react";
import {listAfdApi} from "../../../apis/user";
import {Avatar, Button, Col, Form, List, Result, Space, Spin} from "antd";
import {connect} from "react-redux";



function DonationPanel(props){
    const {userInfo} = props;

    const [page,setPage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])
    const [donationData,setDonationData] = useState({})


    useEffect(() => {
        addData(page)
    }, [page]);


    function addData(page){
        setLoading(true)

        listAfdApi({
            page
        }).then(res=>{
            if(res.code === 200){
                setData([...data, ...res.data.rows])
                setDonationData(res.data)
            }
            setLoading(false)
        }).catch(e=>{
            setLoading(true)
        })
    }

    const onLoadMore = ()=>{
        setPage(page+1)
    }


    const loadMore =
        !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>更多</Button>
            </div>
        ) : null;


    return <div className="login-page">
        <div className="background"></div>
        <Result
            status="success"
            icon={<img src="/forum/static/images/icon.png" alt="PocketDestiny Logo"
                       className="card-logo"/>}
            title="感谢所有支持PocketDestiny的粉丝"
            subTitle={<div>
                为PocketDestiny贡献一份力量，成为PocketDestiny捐赠者!
            </div>}
            extra={[
                <a href={"https://afdian.com/a/PocketDestiny"}><Button type={"primary"}
                                                                       size={"large"}>捐赠</Button></a>,
                <div className="bg-container">
                    <div className="card" id="card">
                        <div>
                            <h2>捐赠者列表 </h2>
                            <span
                                className={"ant-result-subtitle"}>该排名不分先后，感谢以下{donationData.total}位捐赠者</span>
                        </div>
                        <Spin spinning={loading}>
                            <List
                                loadMore={loadMore}
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar}/>}
                                            title={<a href="">{item.name}</a>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Spin>

                    </div>
                </div>
            ]}
        />

    </div>
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationPanel)