import request from "../utils/request";



export function addFavoritesApi(postId, title){
    return request({
        url: `/favorites/add`,
        method: "post",
        data: {
            title,
            postId,
        },
    })
}

export function listFavoritesApi(params){
    return request({
        url: `/favorites/list`,
        method: "get",
        params
    })
}

export function delPostFavoritesApi(ids){
    return request({
        url: `/favorites/${ids}`,
        method: "delete",
    })
}