import "./index.css"
import {Button, Col, Input, Modal, Pagination, Row, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import {delPostApi, listPostApi, myListPostApi} from "../../../../apis/post";
import {useEffect, useState} from "react";
import {dateUtcFormat} from "../../../../utils/TimeUtil";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {success_notice} from "../../../../utils/noticeUtils";


export default function PostManage() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [postTableData, setPostTableData] = useState({
        total: 0,
        page: 1,
        pageSize: 10,
        rows: []
    })

    const onSearch = (type, searchText="", page=1) => {
        setLoading(true);
        myListPostApi({
            type,
            searchText,
            page: page,
            pageSize: 10,
        }).then(res=>{
            if(res.code===200){
                setPostTableData(res.data)
            }

            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        onSearch("all")
    }, [])


    const del = (post)=>{
        Modal.confirm({
            title: ["删除帖子"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认删除帖子 “${post.title}” `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                delPostApi(post.id).then(res=>{
                    if(res.code===200){
                        success_notice("删除成功!")
                        onSearch("all", searchText)
                    }
                })
            },
        });
    }


    return <Spin  spinning={loading}>
        <div className="container">
            <main className="main-content">
                <h1 className="page-title">帖子管理</h1>

                <Row justify={"space-between"} style={{marginBottom: 6}}>
                    <Col>
                        <Input.Search value={searchText} onChange={(val)=>{
                            setSearchText(val.target.value)
                        }}   onSearch={()=>{
                            onSearch("all")
                        }} placeholder="搜索帖子..." />
                    </Col>
                    <Col>
                        <div>
                            <Button type={"primary"} onClick={() => {
                                navigate("/forum/post/add")
                            }}>发帖</Button>
                        </div>
                    </Col>
                </Row>

                <div style={{overflowX: "auto"}}>
                    <table className="post-list">
                        <thead>
                        <tr>
                        <th>标题</th>
                            <th>日期</th>
                            <th>状态</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        {postTableData.rows.map(post=> {
                            return <tr key={post.id}>
                                <td>{post.title}</td>
                                <td>{dateUtcFormat(post.createAt, "YYYY-MM-DD")}</td>
                                <td>已发布</td>
                                <td>
                                    <div className="post-actions">
                                        <a href={`/forum/post/update/${post.id}`}>
                                            <button>编辑</button>
                                        </a>

                                        <button className="delete-btn" onClick={()=>{
                                            del(post)
                                        }}>删除</button>
                                    </div>
                                </td>
                            </tr>
                        })}


                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    <div className="pagination-info">共 {postTableData.total} 条帖子</div>
                    <div className="pagination-controls">
                        <Pagination size="small" current={postTableData?.page || 1}
                                    pageSize={postTableData?.pageSize || 10} total={postTableData?.total}
                                    onChange={(page)=>{
                                        onSearch("all", searchText, page)
                                    }}
                        />
                    </div>
                </div>
            </main>
        </div>

    </Spin>


}


