import "./index.css"
import {Button, Form, Input} from "antd";
import {loginApi} from "../../../apis/user";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

import {changeUserInfoAction} from "../../../redux/action/UserAction";


export default function Login() {
    const [form] = Form.useForm();

    const  [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
        // 获取登录信息，存在登录则跳转论坛首页

    }, [])

    const onLoginClick = ()=>{

        form.validateFields().then(res=>{

            setLoading(true);
            loginApi(res.username, res.password).then(res=>{
                if(res.msg === "Succeed"){
                    changeUserInfoAction(res.data)
                    navigate("/forum")
                }

                setLoading(false);
            }).catch(e=>{
                setLoading(false);
            })

        })
    }



    return <div className="login-page">
        <div className="background"></div>

        <Form
            form={form}
            labelCol={{span: 7}}
            labelAlign={"right"}
            wrapperCol={{span: 14}}
            autoComplete="off"
        >

            <div className="bg-container">
                <div className="card" id="card">
                    <div className="card-face card-front">
                        <div className="card-header">
                            <img src="/forum/static/images/icon.png" alt="PocketDestiny Logo"
                                 className="card-logo"/>
                            <h1 className="card-title">欢迎回到PocketDestiny</h1>
                        </div>
                        <form className="card-form" id="loginForm">
                                <Form.Item
                                    name={"username"}
                                    label={"用户名"}
                                    labelAlign="right"
                                    rules={[{required: true}]}
                                    noStyle

                                >
                                    <Input size={"large"} placeholder="用户名" style={{marginBottom: 24}}/>
                                </Form.Item>

                                <Form.Item
                                    name={"password"}
                                    label={"密码"}
                                    noStyle
                                    labelAlign="right"
                                    rules={[{required: true}]}
                                >
                                    <Input.Password size={"large"} placeholder="密码" style={{marginBottom: 24}}/>
                                </Form.Item>

                            <Button type="primary" size={"large"} onClick={onLoginClick} loading={loading}>登录</Button>
                        </form>
                        <div className="card-footer">
                            <span style={{marginRight: 20}}><a className="link" href={"/forum/forgotPassword"}>忘记密码</a></span>
                            <span><a className="link" href={"/forum/register"}>注册账号</a></span>
                        </div>
                    </div>
                </div>
            </div>

        </Form>


    </div>

}


