import "./index.css"
import {authIsModWorker} from "../../../../utils/authUtil";
import {Button, Divider, Dropdown, Empty, Menu, Pagination, Result, Spin} from "antd";
import RequestModModal from "./modal/RequestModModal";
import {showModalAction} from "../../../../redux/action/modalAction";
import {listSelfModApi, requestModApi} from "../../../../apis/mod";
import {success_notice} from "../../../../utils/noticeUtils";
import {listPostApi} from "../../../../apis/post";
import {useEffect, useState} from "react";


let preType = "all"
let preSearchText = ""

export default function ModManage() {
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState({
        total: 0,
        rows: []
    })


    useEffect(() => {
        onSearch("all")
    }, []);

    const onRequestModOk = (data)=>{

        setLoading(true)
        requestModApi(data).then(res=>{
            if(res.code === 200){
                success_notice("申请成功!")
                onSearch("all")
            }
            setLoading(false)
        }).catch(e=>{
            setLoading(false)
        })
    }

    const onSearch = (type, searchText="", page=1) => {
        preType = type
        preSearchText = searchText

        setLoading(true);
        listSelfModApi({
            type,
            searchText,
            page: page,
            pageSize: 10,
        }).then(res=>{
            if(res.code===200){
                setTableData(res.data)
            }

            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }



    return <Spin spinning={loading}>
        <RequestModModal onOk={onRequestModOk}/>
        <div className="container">
            <main className="main-content">
                <h1 className="page-title">MOD管理</h1>
                <div className="admin-controls">
                    <div className="tab-buttons">
                    </div>
                    <Button type={"primary"} size={"large"} onClick={() => {
                        showModalAction("RequestModModal")
                    }}>申请MOD制作</Button>
                </div>
                <Divider style={{margin: "6px 0"}}/>

                {tableData.rows.length === 0 && <Empty/>}

                {tableData.rows.length > 0 && <div style={{overflowX: "auto"}}>
                    <table className="mod-list" id="in-progress-table">
                        <thead>
                        <tr>
                            <th>MOD名称</th>
                            <th>版本</th>
                            <th>状态</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody >

                        {tableData.rows.map(mod => {
                            return <tr key={mod.id}>
                                <td data-label="MOD名称">{mod.name}</td>
                                <td data-label="版本">{mod.version || "1.0.0"}</td>
                                <td data-label="状态"><span className="status status-in-progress">审核</span></td>
                                <td>
                                    <Button>查看MOD</Button>
                                </td>
                            </tr>

                        })}

                        </tbody>
                    </table>

                </div>}

                <div className="pagination">
                    <div className="pagination-info">共 {tableData.total} 个MOD</div>
                    <div className="pagination-controls">
                        <Pagination size="small" current={tableData?.page || 1}
                                    pageSize={tableData?.pageSize || 10} total={tableData?.total}
                                    onChange={(page) => {
                                        onSearch(preType, preSearchText, page)
                                    }}
                        />
                    </div>
                </div>
            </main>
        </div>

    </Spin>


}


