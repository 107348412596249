import store from "../store";


export function changeUserInfoAction(userInfo){

    store.dispatch({type:"systemInfo/setUserInfo", data:  userInfo})
}



export function getUserInfoActionApi(){
    return store.getState().systemInfo.userInfo;
}