

import {Button, Form, Input, Modal} from "antd";
import {hideModalAction} from "../../../redux/action/modalAction";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {useRules} from "../../../utils/ruleUtil";
import axios from "axios";
import {getMailCodeApi} from "../../../apis/user";




function MailCaptchaModal(props){
    const {visible, data} = props;
    const [form] = Form.useForm();
    const [captchaDataUrl, setCaptchaDataUrl] = useState(null);
    const captchaRef = useRef(null);


    useEffect(()=>{
        if(visible){
            form.resetFields()
            refreshCaptcha();
        }

    },[visible])

    const onCancel = ()=>{
        hideModalAction()
    }

    const refreshCaptcha = async () => {
        try {
            const response = await axios({
                method: 'get',
                url: '/forum/apis/auth/captcha?email='+data?.email,
                responseType: 'blob'
            });

            const dataUrl = URL.createObjectURL(new Blob([response.data], { type: 'image/png' })); // 根据你的图片类型调整 MIME 类型
            setCaptchaDataUrl(dataUrl);


            if (captchaRef.current && captchaRef.current.src) {
                URL.revokeObjectURL(captchaRef.current.src);
            }

        } catch (error) {
            console.error('Failed to fetch captcha:', error);
        }
    };

    const onOk = () => {
        form.validateFields().then(values=>{

            props.onOk(data?.email, values.captchCode)
        })
    }

    return <Modal
        title={`图形验证码（请输入图形验证码进行校验）`}
        visible={visible}
        onCancel={onCancel}
        width={360}
        destroyOnClose={true}
        footer={
            <>
                <Button onClick={onCancel}>{"取消"}</Button>
                <Button onClick={onOk} type="primary">{"确认"}</Button>
            </>
        }
    >
        <Form
            form={form}
            labelCol={{span: 8}}
            labelAlign={"right"}
            wrapperCol={{span: 14}}
            autoComplete="off"
        >
            <Form.Item
                label={"验证码"}
                name="captchCode"
            >
                <Input size={"large"}/>
            </Form.Item>

            <Form.Item
                label={"图形验证码"}
                name="captch"
            >
                <img
                    ref={captchaRef}
                    src={captchaDataUrl}
                    alt="Captcha"
                    onClick={refreshCaptcha} // 你可以将刷新按钮的功能合并到图片点击事件中
                    style={{cursor: 'pointer'}} // 添加样式以指示图片是可点击的
                />
            </Form.Item>
        </Form>
    </Modal>

}


const mapStateToProps = (state) => {
    return {
        visible: state.modal.modalKey === "MailCaptchaModal",
        data: state.modal.modalData,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MailCaptchaModal)

