import "./index.css"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";

import "react-quill/dist/quill.snow.css"
import {Button, Form, Input, Select, Spin, Upload} from "antd";
import {error_notice, success_notice} from "../../../../utils/noticeUtils";
import {uploadImageApi} from "../../../../apis/image";
import {addPostApi, getPostDetailsApi, updatePostApi} from "../../../../apis/post";
import {connect} from "react-redux";
import {addImgHostPath, delImgHostPath, getServerImgPath} from "../../../../utils/ImageUtil";
import ForumQuill from "../../../../component/ForumQuill/Quill";
import {useTypes} from "../../../../utils/TypeUtil";


function PostAdd(props) {
    const {userInfo} = props;
    const {id} = useParams()

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const quillRef = useRef(null);
    let [content, setContent] = useState("");
    const [loadingTitle, setLoadingTitle] = useState(null);
    const types = useTypes();


    useEffect(()=>{
        if(id){
            setLoadingTitle("加载中...")
            getPostDetailsApi(id).then(res=>{
                if (res.code===200) {
                    form.setFieldsValue({
                        sectionId: res.data.sectionId,
                        title: res.data.title,
                    })

                    res.data.content = addImgHostPath(res.data.content)

                    setContent(res.data.content)
                }
                setLoadingTitle(null)
            }).catch(e=>{
                setLoadingTitle(null)
            })
        }



    }, [id])


    const  addImage = (uri)=>{
        if (quillRef) {
            console.log(quillRef, quillRef.current, getServerImgPath(uri))
            const editor = quillRef.current.getEditor();

            const index = editor.getLength();  // 获取当前编辑器内容长度
            editor.insertEmbed(index, "image", getServerImgPath(uri),'user');  // 在末尾位置插入图片
            // 获取插入的图片元素
            editor.setSelection(index+1)
        }
    }

    function uploadImage(file){
        const formData = new FormData();
        formData.append('file', file);

        setLoadingTitle("上传图片中,请稍后...")
        uploadImageApi(formData).then(res=>{

            if (res.code===200) {
                addImage(res.data)
                success_notice('文件上传成功');
            } else {
                error_notice(new Error('文件上传失败'));
            }
            setLoadingTitle(null)
        }).catch(error => {
            console.log("error==>", error)
            error_notice("上传文件错误")
            setLoadingTitle(null)
        });
    }

    const onAdd = ()=>{
        form.validateFields().then(values=>{
            content = delImgHostPath(content)
            if(id){
                setLoadingTitle("更新帖子中,请稍后...")

                updatePostApi(id, values.title, values.sectionId, content).then(res=>{
                    if(res.code === 200){
                        success_notice("更新帖子成功!")
                        navigate("/forum/post")
                    }

                    setLoadingTitle(null)
                }).catch(e=>{
                    error_notice("更新帖子失败!")
                    setLoadingTitle(null)
                })
            }else {
                setLoadingTitle("添加帖子中,请稍后...")
                addPostApi(values.title, values.sectionId, content).then(res=>{
                    if(res.code === 200){
                        success_notice("添加帖子成功!")
                        navigate("/forum/post")
                    }

                    setLoadingTitle(null)

                }).catch(e=>{
                    error_notice("添加帖子失败!")

                    setLoadingTitle(null)
                })
            }


        })
    }

    return <Spin spinning={loadingTitle} tip={loadingTitle}>
        <div className="container">
            <h1>创建新帖子</h1>

            <Form
                form={form}
                labelCol={{span: 7}}
                labelAlign={"right"}
                wrapperCol={{span: 14}}
                autoComplete="off"
            >

                <div className="form-group">
                    <label htmlFor="post-title" className="form-label">标题</label>
                    <Form.Item
                        name={"title"}
                        rules={[{required: true}]}
                        noStyle
                    >
                        <Input size={"large"}/>
                    </Form.Item>
                </div>
                <div className="form-group">
                    <label htmlFor="post-category" className="form-label">分类</label>
                    <Form.Item
                        name={"sectionId"}
                        rules={[{required: true}]}
                        noStyle
                        initialValue={1}
                    >
                        <Select style={{width: "100%"}} size={"large"} options={types.postType}/>
                    </Form.Item>
                </div>

                <div className="form-group">
                    <div>
                        <ForumQuill value={content} uploadImage={uploadImage}  refFun={f => { quillRef.current = f }}  onChange={(value)=>{
                            setContent(value)
                        }}/>
                    </div>
                </div>

                <div className="button-container">
                    <Button type={"primary"} onClick={onAdd} style={{width: "80%", margin:"auto"}} size="large">发布帖子</Button>
                </div>

            </Form>
        </div>

    </Spin>


}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostAdd)



