import request from "../utils/request";


export function listNoticeApi(params){
    return request({
        url: `/notice/list`,
        method: "get",
        params
    })
}

export function noticeReadAllApi(){
    return request({
        url: `/notice/readAll`,
        method: "post"
    })
}



/**
 * 获取未读消息数量
 * @return {*}
 */
export function getNoticeUnreadCountApi(){
    return request({
        url: `/notice/unreadCount`,
        method: "get",
    })
}