


const initState = {
    lang:"en",
    basename: "",
    userInfo:{
        username: "",
        email:"",
    },

    userResource:{

    }
};


export default function SystemInfoReducer(state = initState, action){
    const {type, data} = action;

    switch(type) {
        case "systemInfo/setUserInfo":
            return {...state, userInfo: data};
        default:
            return state
    }
};
