

const initState = {
    modalKey : "",
    modalData: {}, // 需要传递给Modal的数据
};


export default function reducer(state = initState, action){
    const {type, data} = action;

    switch(type) {
        case "modal/change":
            return {
                modalKey: data.key,
                modalData: data.data
            };
        default:
            return state
    }

};
