import axios from "axios";
import {dateFormat} from "./TimeUtil";
import config from "../appConfig";
import {error_notice} from "./noticeUtils";




// 创建一个 axios 实例
const service = axios.create({
	baseURL: `/${config.appName}/apis`, // 请求地址前缀部分为/应用名/apis
	timeout: 20000, // 请求超时时间毫秒
});

// 添加请求拦截器
service.interceptors.request.use(config => {

	// get请求映射params参数
	if (config.method === 'get' && config.params) {
		let url = config.url + '?'+tansParams(config.params);
		config.params = {};
		config.url = url;
	}

	parseDate(config.data);

	return config
}, error => {
	console.log(error);
	Promise.reject(error)
});



// 添加响应拦截器
service.interceptors.response.use(
	function (response) {
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么

		// dataAxios 是 axios 返回数据中的 data
		const resp = response.data;

		if(resp.code !== 200){
			// 错误
			error_notice(resp.msg);
		}

		if(resp.code === 401){
			// 401跳转到登录界面
			window.location.href = "/forum/login"
			return
		}

		// 这个状态码是和后端约定的
		// const code = dataAxios.reset;
		return resp;
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么

		// error_notice("server error", error.message);

		return Promise.reject(error);
	}
);


/**
 * 对象属性时间对象格式化
 */
export function parseDate(data) {
	if(data){
		for (const propName of Object.keys(data)) {
			let value = data[propName];
			if (value !== null && typeof (value) !== "undefined"){
				if (typeof value === 'object') {
					if (value._isAMomentObject) {
						// 提交时时间对象格式化处理
						data[propName] = value = dateFormat(value);
					}
				}
			}
		}
	}
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
	let result = '';
	for (const propName of Object.keys(params)) {
		let value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && typeof (value) !== "undefined") {
			if (typeof value === 'object') {
				if(value._isAMomentObject){
					// 提交时时间对象格式化处理
					value = dateFormat(value);
					let subPart = encodeURIComponent(propName) + "=";
					result += subPart + encodeURIComponent(value) + "&";
					continue;
				}

				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof (value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						let subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
}

/**
 * 获取url中search参数
 * @param name
 * @return {null|string}
 */
export function getQueryString(name) {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = window.location.search.substr(1).match(reg);
	if (r != null) return (r[2]); return null;
}

export default service;
