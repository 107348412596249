
import './index.less'
import {Button, Col, Row} from "antd";
import {getCookie} from "../../utils/cookieUtil";
import {useState} from "react";
import {setCookie} from "../../utils/dataStore/cookieStore";


const agree_name = "agree"

export default function UserAgreementTip(){
    const [isAgree, setIsAgree] = useState(getCookie(agree_name))

    return <div id="agreement" style={{
        display: isAgree? "none": "block"
    }}>
       <Row justify={"space-between"}>
           <Col style={{color: "white"}}>
               <h2 style={{color: "white"}}>用户协议</h2>
               <span>使用此网站即表示您同意接受<a href={"/userAgreement"}>用户协议</a>。</span>
           </Col>
           <Col>
               <Button size={"large"} style={{marginTop: 15}} onClick={()=>{
                   setIsAgree(true)
                   setCookie(agree_name, true, 30)
               }}>选择同意</Button>
           </Col>
       </Row>
    </div>
}