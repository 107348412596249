import {notification, message} from "antd";

message.config({
    duration:2,
});
/**
 * 提示框
 * @param type 提示框类型
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const notice = (type, msg, description, key)=>{

    let content = msg ? msg: "";

    if(description){
        content += msg? ", "+description: description;
    }

    message[type]({
        className:"esp-micro-container",
        content: content,
        key: key,
        duration: 2,
    });

};

/**
 * 成功提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const success_notice = (msg, description, key)=>{
    notice('success', msg, description, key)
};

/**
 * 消息提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const info_notice = (msg, description, key)=>{
    notice('info', msg, description, key)
}

/**
 * 警告提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const warning_notice = (msg, description, key)=>{
    notice('warning', msg, description, key)
}

/**
 * 错误提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const error_notice = (msg, description, key)=>{
    notice('error', msg, description, key)
}

/**
 * 可以通过flag改变key对应的提示框内容的提示框
 * @param type 提示框类型
 * @param flag 根据flag更改内容
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const notice_key = (type, flag, msg, description, key)=>{
    notification[type]({
        message: flag ? msg[0] : msg[1],
        className:"esp-micro-container",
        description: flag ? description[0] : description[1],
        placement:"topRight",
        key: key,
        duration: 2,
    });
}

/**
 * 成功提示
 * @param msg 标题
 * @param flag 根据flag改变内容
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const success_notice_key = (msg, flag, description, key)=>{
    notice('success', flag, msg, description, key)
}

/**
 * 消息提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const info_notice_key = (msg, description, key)=>{
    notice('info', msg, description, key)
}

/**
 * 警告提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const warning_notice_key = (msg, description, key)=>{
    notice('warning', msg, description, key)
}

/**
 * 错误提示
 * @param msg 标题
 * @param description 内容
 * @param key 唯一标识(可选)
 */
export const error_notice_key = (msg, description, key)=>{
    notice('error', msg, description, key)
}
