import request from "../utils/request";



export function loginApi(username, password){
    return request({
        url: `/auth/login`,
        method: "post",
        data: {
            username,
            password
        },
    })
}


export function logoutApi(){
    return request({
        url: `/auth/logout`,
        method: "post"
    })
}


export function getLoginInfoApi(){
    return request({
        url: `/user/login/info`,
        method: "post"
    })
}


export function updateUserApi(nickname, sex, profile, tags){
    return request({
        url: `/user/update`,
        method: "post",
        data: {
            nickname,
            sex,
            profile,
            tags,
        },
    })
}

export function listAfdApi(params){
    return request({
        url: `/user/afdList`,
        method: "post",
        data: params,
    })
}




export function getMailCodeApi(email, captchaCode=""){
    return request({
        url: `/auth/getMailCode`,
        method: "post",
        params: {
            email,
            captchaCode
        },
    })
}

export function getForgotMailCodeApi(email, captchaCode=""){
    return request({
        url: `/forgot/getMailCode`,
        method: "post",
        params: {
            email,
            captchaCode
        },
    })
}

export function forgotResetPasswordApi(email,password,code){
    return request({
        url: `/forgot/resetPassword`,
        method: "post",
        params: {
            email,
            password,
            code,
        },
    })
}


export function registerApi(username,email,password,code){
    return request({
        url: `/auth/register`,
        method: "post",
        params: {
            username,
            email,
            password,
            code,
        },
    })
}






