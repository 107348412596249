import {Col, Menu, Row} from "antd";
import MenuItem from "antd/es/menu/MenuItem";

import './index.css'
import {useNavigate} from "react-router-dom";
import {EllipsisOutlined} from "@ant-design/icons";



export default function Header() {
    const navigate = useNavigate();

    const onMenuClick = (info)=>{
        navigate(info.key)
    }

    
    return <div className={"destiny-header"} >
        <nav className="nav">
            <Row justify="space-between" className={"header-row"}>
                <Col>
                    <a href="/" className="header-brand">PocketDestiny</a>
                </Col>
                <Col>
                    <Menu
                        className={"header-menu"}
                        mode="horizontal"
                        theme="dark"
                        onClick={onMenuClick}
                        overflowedIndicator={<div style={{height: "100%", width: 30}}><EllipsisOutlined style={{color: "white", fontSize: 24, position:"absolute", top:12}}/></div>}
                    >
                        <MenuItem key="/">首页</MenuItem>
                        <MenuItem key="/download">下载</MenuItem>
                        <MenuItem key="/forum">论坛</MenuItem>
                    </Menu>
                </Col>
            </Row>

        </nav>
    </div>
}