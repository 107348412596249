import './index.css'
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, List, Divider, Row, Col, Spin, Badge, Space} from "antd";
import {getNoticeUnreadCountApi, listNoticeApi, noticeReadAllApi} from "../../../apis/notice";
import {useNavigate} from "react-router-dom";
import {dateUtcFormat} from "../../../utils/TimeUtil";

let preType = "all"
let preSearchText = ""


const typeText = {
    post: "帖子通知",
    workOrder: "工单消息",
}
function Notice(props) {
    const [page,setPage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])
    const [dataArg,setDataArg] = useState({
        page: 1,
        total: 0,
        pageSize: 20,
    })

    const navigate = useNavigate();

    const [noticeUnreadCount, setNoticeUnreadCount] =  useState(0)

    useEffect(() => {

        getNoticeUnreadCountApi().then(res=>{
            if(res.code === 200){
                setNoticeUnreadCount(res.data)
            }
        })

    }, [])

    useEffect(() => {
        addData(page)
    }, [page]);


    function addData(page){
        setLoading(true)
        listNoticeApi({
            page,
            pageSize: 20,
        }).then(res=>{
            if(res.code === 200){
                setData([...data, ...res.data.rows])
                setDataArg(res.data)
            }

            setLoading(false)
        }).catch(e=>{
            setLoading(false)
        })
    }

    const onLoadMore = ()=>{
        setPage(page+1)
    }


    const loadMore =
        !loading && dataArg.page*dataArg.pageSize < dataArg.total ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>更多</Button>
            </div>
        ) : null;

    const onclickMessage = (message) => {
      if(message.type === "post"){
          navigate(`/forum/post/details/${message.triggerId}`)
      }

      if(message.type === "workOrder"){
          navigate(`/forum/workOrder/details/${message.triggerId}`)
      }

    }


    return <div className="login-page">
        <Spin spinning={loading}>
            <div className="background"></div>
            <div className="bg-container">
                <div className="card" id="card">
                    <Row justify={"space-between"}>
                        <Col>
                            <div>
                                <h2>通知消息</h2>
                                <span
                                    className={"ant-result-subtitle"}>共{dataArg?.total}条消息, {noticeUnreadCount}条未读</span>
                            </div>
                        </Col>
                        <Col>
                            <Button type={"primary"} onClick={() => {
                                setLoading(true)
                                noticeReadAllApi().then(res => {
                                    if (res.code === 200) {
                                        document.location.reload()
                                    }

                                    setLoading(false)
                                }).catch(e=>{
                                    setLoading(false)
                                })
                            }}>全部已读</Button>
                        </Col>
                    </Row>

                    <Divider style={{margin: " 5px 0"}}/>

                    <List
                        loadMore={loadMore}
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Space>
                                                <a href="" onClick={() => {
                                                    onclickMessage(item)
                                                }}>{index}. {typeText[item.type]} </a>
                                                <Badge dot={item.isRead === 0}/>
                                            </Space>
                                        }
                                        description={dateUtcFormat(item.createAt)+" "+item.message}
                                    />
                                </List.Item>

                        )}
                    />
                </div>
            </div>
        </Spin>

    </div>
}


const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notice)