import {combineReducers} from 'redux'

import systemInfo from './reducer/SystemInfoReducer'
import modal from './reducer/ModalReducer'


// reducers
export default combineReducers({
    systemInfo, // 系统信息

    modal, // 控制model的显示与数据
})
