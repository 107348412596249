import request from "../utils/request";


export function uploadImageApi(formData){
    return request({
        url: `/image/upload`,
        method: "post",
        data: formData,
    })
}

export function uploadHeadImageApi(formData){
    return request({
        url: `/image/head/upload`,
        method: "post",
        data: formData,
    })
}