
const host = "https://image.pocketdestiny.com"


export function getServerImgPath(path){

    return path ? `${host}${path}`: ""
}


export function addImgHostPath(str){
    // 使用正则表达式替换src属性的路径
    let modifiedHtmlString = str.replace(/(<img\s+[^>]*src=")([^"]+")/g, function(match, p1, p2) {
        // p1 是匹配到的 <img ... src=" 部分
        // p2 是匹配到的 src 属性值（即路径）
        // 返回替换后的字符串，其中 p2（路径）前加上了 /xx 前缀
        return p1 + host + p2;
    });

    return modifiedHtmlString
}

export function delImgHostPath(str){
    return str.replace("src=\""+host, "src=\"");
}



