import {Button, Form, Input, Modal} from "antd";
import {connect} from "react-redux";
import {hideModalAction} from "../../../../../redux/action/modalAction";
import {useRules} from "../../../../../utils/ruleUtil";
import {useEffect} from "react";


function RequestModModal(props){
    const {visible} = props;
    const rules = useRules()
    const [form] = Form.useForm();


    useEffect(() => {
        if(visible){
            form.resetFields()
        }
    }, [visible]);

    const onCancel = ()=>{
        hideModalAction()
    }

    const onOk = ()=>{
        hideModalAction()
        form.validateFields().then(res=>{
            props.onOk(res)
        })

    }

    return <Modal
        title={"申请MOD制作"}
        className={"nms-perf-modal"}
        visible={visible}
        onCancel={onCancel}
        destroyOnClose={true}
        footer={
            <>
                <Button onClick={onCancel}>{'取消'}</Button>
                <Button onClick={onOk} type="primary">{"保存"}</Button>
            </>
        }
    >
        <Form
            form={form}
            labelCol={{span:6}}
            labelAlign={"right"}
            wrapperCol={{span:16}}
            autoComplete="off"
        >

            <Form.Item
                label={"MOD名称"}
                name="modName"
                rules={[rules.name]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label={"MOD描述"}
                name="modDesc"
            >
                <Input.TextArea style={{height: 200}} maxLength={150} showCount />
            </Form.Item>
        </Form>

    </Modal>
}

const mapStateToProps = (state) => {
    return {
        visible: state.modal.modalKey === "RequestModModal",
        data: state.modal.modalData,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RequestModModal)