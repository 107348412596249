import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import  {Quill} from "react-quill";

import "react-quill/dist/quill.snow.css"
import {Button, Input, Space, Spin} from "antd";
import {dateUtcFormat} from "../../../../utils/TimeUtil";
import {error_notice, success_notice} from "../../../../utils/noticeUtils";
import {connect} from "react-redux";
import {addWorkOrderCommentApi, getWorkOrderApi, listWorkOrderCommentApi} from "../../../../apis/workOrder";
import {addImgHostPath, getServerImgPath} from "../../../../utils/ImageUtil";
import {CommonProblemTops} from "../Manage";



function WorkOrderInfo(props) {
    const {userInfo} = props;

    const navigate = useNavigate();
    const [loadingTitle, setLoadingTitle] = useState(null);
    const [post, setPost] = useState({});
    const [comment, setComment] = useState("");

    const [commentTableData, setCommentTableData] = useState({
        rows:[],
        total: 0,
    });

    const {id} = useParams()


    useEffect(()=>{
        setLoadingTitle(" ")
        getWorkOrderApi(id).then(res=>{
            if(res.code === 200){
                res.data.content = addImgHostPath(res.data.content)
                setPost(res.data)
            }

            setLoadingTitle(null)
        })

        queryComment(1)

        // queryComment(1)
    }, [id])


    const queryComment = (page=1)=>{
        listWorkOrderCommentApi({
            page: page,
            pageSize: 10,
            workOrderId: id,
        }).then(res=>{
            if(res.code === 200){
                setCommentTableData(res.data)
            }
        })
    }


    const onSubmitComment = () => {
        if(!comment){
            error_notice("无法提交空的评论!")
            return
        }

        addWorkOrderCommentApi(id, comment).then(res=>{
            if(res.code === 200){
                success_notice("评论成功")
                queryComment()
            }

        }).catch(e=>{
            error_notice("评论失败")
        })

    }


    return <Spin spinning={loadingTitle} tip={loadingTitle}>
        <div className="post-details-page">
            <div className="post-details-page-container">
                <main className="main-content">
                    <article>
                        <header className="article-header">
                            <h1 className="article-title">{post?.title}</h1>
                            <div className="article-meta">
                                <span>用户：{post.userInfo?.nickname}</span>
                                <span>时间：{post.createAt && dateUtcFormat(post.createAt)}</span>
                            </div>
                        </header>
                        <div className="article-content" dangerouslySetInnerHTML={{__html: post?.content || ""}}>

                        </div>
                    </article>

                    <section className="comment-section">
                        <h2>回复记录</h2>
                        {
                            userInfo?.username ? <form className="comment-form">
                                <Input.TextArea value={comment} onChange={(e) => {
                                    setComment(e.target.value)
                                }} placeholder="回复内容..."></Input.TextArea>
                                <Button type={"primary"} style={{marginTop: 5}}
                                        onClick={onSubmitComment}>回复</Button>
                            </form> : <div>
                                <label style={{color: "#7f8c8d"}}>登录后方可回复 </label>
                                <a href="/forum/login" >立即登录</a>
                            </div>
                        }


                        <div className="comments">

                            {commentTableData.rows.map(comment => {

                                return <div className="comment" id={comment.id}>

                                    <img src={getServerImgPath(comment.userInfo?.headUrl)} alt="用户头像"
                                         className="post-avatar"/>
                                    <div className="comment-content">
                                        <div className="comment-author">{comment.userInfo?.nickname}
                                        </div>
                                        <p> {comment.content} </p>
                                        <div className="comment-date">{dateUtcFormat(comment.createAt)}</div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </section>
                </main>

                <aside className="sidebar">
                   <CommonProblemTops/>
                </aside>
            </div>

            <button className="theme-toggle">🌓</button>
        </div>

    </Spin>


}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderInfo)

// 自定义的图片处理模块
const ImageBlot = Quill.import('blots/embed');

class Image extends ImageBlot {
    static create(value) {
        let node = super.create();
        node.setAttribute('src', value);
        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}

Image.blotName = 'image';
Image.tagName = 'img';
Quill.register(Image);


