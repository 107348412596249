


// 规则验证工具类


// 该语言集为类型到真实文本做的映射
const languages= {
    required:{zh: "该项必填", en: "This item is required."},
    length:{zh: "该项长度不能大于50个字符", en: "The item cannot be longer than 50 characters."},
    mac:{zh: "MAC地址不合法", en: "The MAC Address is illegal."},
    number:{zh: "必须为大于0的数字", en: "The Number must be greater than 0."},
    smallNumber:{zh: "数字过大", en: "The number is too large."},
    name:"名称长度不超过30位汉字/数字/英文字母,可包含 . - _ : 且不能为空, 不能包含非法字符",
    space:"开头或结尾不能包含空格",
    integer:"请输入{min}~{max}的整数",
};



export function useRules() {

    const validateName = (rule, value) => {
        if (!value) {
            return Promise.reject(languages.name);
        }

        const trimmedValue = value.trim();

        if (trimmedValue.length !== value.length) {
            return Promise.reject(languages.space);
        }

        if (value.length > 30) {
            return Promise.reject(languages.name);
        }

        const regex = /^[\u4e00-\u9fa5a-zA-Z0-9\.\-_: ]*$/;
        if (!regex.test(trimmedValue)) {
            return Promise.reject(languages.name);
        }

        return Promise.resolve();
    };

    const validateInteger = (rule, value) => {
        if(rule.required && !value){
            let tip = languages.integer.replace("{min}", rule.minNum);
            tip = tip.replace("{max}", rule.maxNum);
            return Promise.reject(tip);
        }else {
            if(value){
                value = value+"";
                let validateValue = value;
                // 验证是否
                if(validateValue){
                    validateValue = value.trim();
                    if(validateValue.length < value.length){
                        let tip = languages.integer.replace("{min}", rule.minNum);
                        tip = tip.replace("{max}", rule.maxNum);
                        return Promise.reject(tip);
                    }
                }

                validateValue = parseInt(validateValue);

                // 验证输入是否全为数字
                const regex = /^[0-9]*$/;
                if (!regex.test(value)) {
                    let tip = languages.integer.replace("{min}", rule.minNum);
                    tip = tip.replace("{max}", rule.maxNum);
                    return Promise.reject(tip);
                }

                // 验证输入范围
                if (!isNaN(validateValue)){
                    if(rule.minNum <= validateValue && rule.maxNum >= validateValue){

                    }else {
                        let tip = languages.integer.replace("{min}", rule.minNum);
                        tip = tip.replace("{max}", rule.maxNum);
                        return Promise.reject(tip);
                    }
                }
            }

        }

        return Promise.resolve();
    };


    return {
        name:{required: true, validator:validateName},
        integer:{minNum: 0, maxNum: 100, validator:validateInteger}
    }
}