import {Col, ConfigProvider, Divider, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {BrowserRouter, useNavigate} from "react-router-dom";
import 'moment/locale/zh-cn';

import {Provider} from "react-redux"
import store from "./redux/store"
import {Route, Routes} from "react-router";
import ForumIndex from "./page/forum/Index";
import ForumHeader from "./page/forum/Layout/Header";
import Header from "./page/Layout/Header";
import Index from "./page/Index";
import Download from "./page/Download";
import UserInfo from "./page/forum/UserInfo";
import PostManage from "./page/forum/Post/Manage";
import ModManage from "./page/forum/Mod/Manage";
import PostAdd from "./page/forum/Post/Add";
import Login from "./page/forum/Login";
import {getLoginInfoApi} from "./apis/user";
import {changeUserInfoAction} from "./redux/action/UserAction";
import PostInfo from "./page/forum/Post/Info";
import WorkOrderManage from "./page/forum/WorkOrder/Manage";
import WorkOrderAdd from "./page/forum/WorkOrder/Add";
import WorkOrderInfo from "./page/forum/WorkOrder/Info";
import UserUpdate from "./page/forum/UserInfo/Update";
import Register from "./page/forum/Login/Register";
import CommonProblem from "./page/forum/WorkOrder/CommonProblem";
import ForgotPassword from "./page/forum/Login/ForgotPassword";
import DonationPanel from "./page/forum/Donation/DonationPanel";
import UserAgreementTip from "./page/agreement/UserAgreementTip";
import UserAgreement from "./page/agreement/UserAgreement";
import Notice from "./page/forum/Notice";
import WorkOrderExamine from "./page/forum/WorkOrder/Examine";



// 支持replaceAll方法
// eslint-disable-next-line
if(String.prototype.replaceAll == undefined){
    // eslint-disable-next-line
    String.prototype.replaceAll = function (targetStr, newStr) {
        var sourceStr = this.valueOf();

        while (sourceStr.indexOf(targetStr) !== -1) {
            sourceStr = sourceStr.replace(targetStr, newStr);
        }

        return sourceStr;
    };
}

const App = () => {

    useEffect(()=>{
        getLoginInfoApi().then(res=>{
            changeUserInfoAction(res.data)
        })

    }, [])

    return (<div>
            <BrowserRouter >
                <Routes>
                    <Route path={"/forum/*"} element={<ForumApp/>} />
                    <Route path={"/*"} element={<OfficialWebsiteApp/>} />
                </Routes>
            </BrowserRouter>
            <Footer/>
            <UserAgreementTip/>
        </div>
    );
};


function Footer(){

    return <div className={"forum-footer"}>
        <div className={"forum-container"}>
            <Row gutter={70}>
                <Col>
                    <Space  direction={'vertical'} >
                        <div style={{color: "#9499A0"}}>PocketDestiny</div>
                        <a href={"/"} target={"_blank"} style={{color: "black"}}>PocketDestiny官网</a>
                        <a href={"/forum"} target={"_blank"} style={{color: "black"}}>PocketDestiny论坛</a>
                        <a href={"/userAgreement"} target={"_blank"} style={{color: "black"}}>用户协议</a>
                    </Space>
                </Col>
                <Col>
                    <Space  direction={'vertical'} >
                        <div style={{color: "#9499A0"}}>下载</div>
                        <a href={"/download"} target={"_blank"} style={{color: "black"}}>游戏下载</a>
                    </Space>
                </Col>

                <Col>
                    <Space  direction={'vertical'} >
                        <div style={{color: "#9499A0"}}>关于我们</div>
                        <a href={"https://space.bilibili.com/472379096"} target={"_blank"} style={{color: "black"}}>哔哩哔哩</a>
                        <a href={"https://afdian.com/a/PocketDestiny"} target={"_blank"} style={{color: "black"}}>爱发电</a>
                    </Space>
                </Col>
            </Row>

        </div>
    </div>
}


function ForumApp() {

    return <div>
    <ForumHeader/>
        <Routes>
            <Route path={"/"} element={<ForumIndex/>}/>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/register"} element={<Register/>} />
            <Route path={"/forgotPassword"} element={<ForgotPassword/>} />
            <Route path={"/userInfo"} element={<UserInfo/>} />
            <Route path={"/user/update"} element={<UserUpdate/>} />
            <Route path={"/notice"} element={<Notice/>} />
            <Route path={"/post"} element={<PostManage/>} />
            <Route path={"/post/add"} element={<PostAdd/>} />
            <Route path={"/post/update/:id"} element={<PostAdd/>} />
            <Route path={"/post/details/:id"} element={<PostInfo/>} />
            <Route path={"/workOrder"} element={<WorkOrderManage/>} />
            <Route path={"/workOrder/examine"} element={<WorkOrderExamine/>} />
            <Route path={"/workOrder/details/:id"} element={<WorkOrderInfo/>} />
            <Route path={"/workOrder/add"} element={<WorkOrderAdd/>} />
            <Route path={"/mod"} element={<ModManage/>} />
            <Route path={"/commonProblem"} element={<CommonProblem/>} />
            <Route path={"/donationPanel"} element={<DonationPanel/>} />

        </Routes>
    </div>
}


function OfficialWebsiteApp(){
    return <div>
        <Header/>
        <Routes>
            <Route path={"/"} element={<Index/>} />
            <Route path={"/download"} element={<Download/>} />
            <Route path={"/userAgreement"} element={<UserAgreement/>} />
        </Routes>
    </div>
}

function AppPlus() {
    return (
        <ConfigProvider >
            <Provider store={store}>
                <App />
            </Provider>
        </ConfigProvider>
    )

}

export default AppPlus;
