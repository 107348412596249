import "./index.css"
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import ReactQuill, {Quill} from "react-quill";

import "react-quill/dist/quill.snow.css"
import {Button, Form, Input, Select, Spin, Upload} from "antd";
import {error_notice, success_notice} from "../../../../utils/noticeUtils";
import {uploadImageApi} from "../../../../apis/image";
import {addWorkOrderApi} from "../../../../apis/workOrder";
import {delImgHostPath, getServerImgPath} from "../../../../utils/ImageUtil";
import ForumQuill from "../../../../component/ForumQuill/Quill";


export default function WorkOrderAdd() {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const quillRef = useRef(null);
    let [content, setContent] = useState("");
    const [loadingTitle, setLoadingTitle] = useState(null);


    useEffect(()=>{

    }, [])



    const  addImage = (uri)=>{
        if (quillRef) {
            const editor = quillRef.current.getEditor();
            const index = editor.getLength();  // 获取当前编辑器内容长度
            editor.insertEmbed(index, "image", getServerImgPath(uri),'user');  // 在末尾位置插入图片
            // 获取插入的图片元素
            editor.setSelection(index+1)
        }
    }


    const uploadImage = (file)=>{
        // 你可以在这里执行任何需要的文件处理逻辑
        // 示例：发送文件到服务器
        const formData = new FormData();
        formData.append('file', file);

        setLoadingTitle("上传图片中,请稍后...")
        uploadImageApi(formData).then(res=>{
            if (res.code===200) {
                addImage(res.data)
                success_notice('文件上传成功');
            } else {
                error_notice(new Error('文件上传失败'));
            }
            setLoadingTitle(null)
        }).catch(error => {
            error_notice("上传文件错误")
            setLoadingTitle(null)
        });
    }

    const onAdd = ()=>{
        form.validateFields().then(values=>{
            setLoadingTitle("创建工单中,请稍后...")
            content = delImgHostPath(content)

            addWorkOrderApi(values.title, values.type, content).then(res=>{
                if(res.code === 200){
                    success_notice("添加工单成功!")
                    navigate("/forum/workOrder")
                }

                setLoadingTitle(null)
            }).catch(e=>{
                error_notice("添加工单失败!")
                setLoadingTitle(null)
            })
        })
    }

    return <Spin spinning={loadingTitle} tip={loadingTitle}>
            <Form
                form={form}
                labelCol={{span: 7}}
                labelAlign={"right"}
                wrapperCol={{span: 14}}
                autoComplete="off"
            >

                <div className="ticket-container">
                    <div className="ticket-section">
                        <div className="section-header">
                            <h2 className="section-title">新建工单</h2>
                        </div>
                        <div className="ticket-content">
                            <form id="new-ticket-form">
                                <div className="form-group">
                                    <label htmlFor="ticket-title">标题</label>
                                    <Form.Item
                                        name={"title"}
                                        rules={[{required: true}]}
                                        noStyle
                                    >
                                        <Input size={"large"} placeholder="请简要描述您的问题"/>
                                    </Form.Item>

                                </div>
                                <div className="form-group">
                                    <label htmlFor="ticket-type">问题类型</label>
                                    <Form.Item
                                        name={"type"}
                                        rules={[{required: true}]}
                                        noStyle
                                        initialValue={"game"}
                                    >
                                        <Select style={{width: "100%"}} size={"large"} options={
                                            [
                                                {value: "game", label: "游戏问题"},
                                                {value: "login", label: "登录问题"},
                                                {value: "payment", label: "支付问题"},
                                                {value: "account", label: "账号问题"},
                                                {value: "other", label: "其他"},
                                            ]}/>
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <div>
                                        <ForumQuill value={content} uploadImage={uploadImage} refFun={f => { quillRef.current = f }} onChange={(value)=>{
                                            setContent(value)
                                        }}/>
                                    </div>
                                </div>

                                <Button type={"primary"} onClick={onAdd} style={{width: "100%"}}
                                        size="large">提交工单</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </Form>
    </Spin>


}



