import request from "../utils/request";


export function listNewsApi(params){
    return request({
        url: `/news/list`,
        method: "get",
        params
    })
}


export function addNewsApi(type, title, url){
    return request({
        url: `/news/add`,
        method: "post",
        data:{
            type,
            title,
            url
        }
    })
}