import request from "../utils/request";


export function addPostApi(title, sectionId, content){
    return request({
        url: `/post/add`,
        method: "post",
        data: {
            title,
            sectionId,
            content
        },
    })
}

export function likePostApi(id){
    return request({
        url: `/post/like`,
        method: "post",
        data: {
            id
        },
    })
}

export function updatePostApi(id, title, sectionId, content){
    return request({
        url: `/post/update`,
        method: "post",
        data: {
            id,
            title,
            sectionId,
            content
        },
    })
}

export function listPostApi(params){
    return request({
        url: `/post/list`,
        method: "get",
        params
    })
}


export function myListPostApi(params){
    return request({
        url: `/post//my/list`,
        method: "get",
        params
    })
}

export function postStatisticsApi(userId){
    return request({
        url: `/post/statistics/${userId}`,
        method: "get",
    })
}

export function postStatisticsCountApi(){
    return request({
        url: `/post/statisticsPost`,
        method: "get",
    })
}


export function getPostDetailsApi(id){
    return request({
        url: `/post/details/${id}`,
        method: "get",
    })
}

export function getHotPostApi(){
    return request({
        url: `/post/hot`,
        method: "get",
    })
}

export function delPostApi(ids){
    return request({
        url: `/post/${ids}`,
        method: "delete",
    })
}


export function addPostCommentApi(postId, content, replyId=null){
    return request({
        url: `/post/comment/add`,
        method: "post",
        data: {
            postId,
            content,
            replyId
        },
    })
}


export function listPostCommentApi(params){
    return request({
        url: `/post/comment/list`,
        method: "get",
        params
    })
}

