import './index.css'
import {Button, Col, Form, Input, Row, Select, Space, Spin, Upload} from "antd";
import {useEffect, useState} from "react";

import {connect} from "react-redux";
import {uploadHeadImageApi} from "../../../../apis/image";
import {error_notice, success_notice} from "../../../../utils/noticeUtils";
import {useNavigate} from "react-router-dom";
import {updateUserApi} from "../../../../apis/user";
import {changeUserInfoAction} from "../../../../redux/action/UserAction";
import {getServerImgPath} from "../../../../utils/ImageUtil";



function UserUpdate(props) {
    const {userInfo} = props;
    const [loadingTitle, setLoadingTitle] = useState(null);

    const [form] = Form.useForm();
    const navigate = useNavigate();


    useEffect(()=>{
        if(userInfo){
            let values = {
                profile: userInfo.profile,
                sex: userInfo.sex,
                nickname: userInfo.nickname,
            }

            if(userInfo.tags){
                values["tags"] =  userInfo.tags.split(",");
            }

            form.setFieldsValue(values)
        }

    }, [userInfo])

    const customRequest = ({ file, onSuccess, onError, withCredentials }) => {
        // 你可以在这里执行任何需要的文件处理逻辑
        // 示例：发送文件到服务器
        const formData = new FormData();
        formData.append('file', file);

        setLoadingTitle("上传头像中,请稍后...")
        uploadHeadImageApi(formData).then(res=>{
            if (res.code===200) {
                changeUserInfoAction(res.data)

                success_notice('更新头像成功');
            } else {
                error_notice(new Error('文件上传失败'));
            }
            setLoadingTitle(null)
        }).catch(error => {
            error_notice("上传文件错误")
            setLoadingTitle(null)
        });
    }

    const onSave = ()=>{
        console.log("aaa=>")
        form.validateFields().then(res=>{
            setLoadingTitle("保存中...")

            let tags = ""
            if(res.tags){
                tags = res.tags.join(",")
            }

            updateUserApi(res.nickname, res.sex, res.profile, tags).then(res=>{
                if (res.code===200) {
                    success_notice('更新成功');
                    changeUserInfoAction(res.data)
                    navigate("/forum/userInfo")
                }
                setLoadingTitle(null)
            }).catch(error => {
                error_notice("更新失败")
                setLoadingTitle(null)
            });
        })

    }

    return <Spin spinning={loadingTitle} tip={loadingTitle}>
        <div className="edit-profile-container">
            <div className="edit-profile-header">
                <h1>编辑个人资料</h1>
            </div>
            <form className="edit-profile-form">
                <div className="form-group avatar-upload">
                    <img src={getServerImgPath(userInfo?.headUrl)} alt="当前头像" className="avatar-preview"
                         id="avatar-preview"/>
                    <Upload
                        name="avatar"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={customRequest}
                        accept="image/*"
                    >
                        <label htmlFor="avatar-input">更换头像</label>
                    </Upload>
                </div>

                <Form
                    form={form}
                    labelCol={{span: 7}}
                    labelAlign={"right"}
                    wrapperCol={{span: 14}}
                    autoComplete="off"
                >
                    <div className="form-group">
                        <label htmlFor="username">用户名</label>

                        <Form.Item
                            name={"nickname"}
                            rules={[{required: true}]}
                            noStyle
                        >
                            <Input size={"large"}/>
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label htmlFor="gender">性别</label>

                        <Form.Item
                            name={"sex"}
                            rules={[{required: true}]}
                            noStyle
                            initialValue={"discuss"}
                        >
                            <Select style={{width: "100%"}} size={"large"} options={
                                [{label: "男", value: "male"},
                                    {label: "女", value: "female"},
                                    {label: "其他", value: "other"}]}/>
                        </Form.Item>

                    </div>
                    <div className="form-group">
                        <label htmlFor="bio">个人简介</label>
                        <Form.Item
                            name={"profile"}
                            rules={[{required: true}]}
                            noStyle
                        >
                            <Input.TextArea size={"large"} />
                        </Form.Item>

                    </div>
                    <div className="form-group">
                        <label htmlFor="tags">个人标签</label>

                        <Form.Item
                            name={"tags"}
                            rules={[{required: true}]}
                            noStyle
                        >
                            <Select
                                mode="tags"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Tags Mode"
                            />
                        </Form.Item>
                    </div>

                </Form>

                <div>
                    <Row justify="end">
                        <Col>
                            <Space>
                                <Button size={"large"} onClick={() => {
                                    navigate(-1)
                                }}>取消
                                </Button>

                                <Button size={"large"} type={"primary"} onClick={onSave}>保存</Button>
                            </Space>
                        </Col>
                    </Row>

                </div>
            </form>
        </div>
    </Spin>


}


const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdate)