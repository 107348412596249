import {Button, Form, Input, Modal, Select} from "antd";
import {connect} from "react-redux";

import {useEffect} from "react";
import {useRules} from "../../../../utils/ruleUtil";
import {hideModalAction} from "../../../../redux/action/modalAction";


function AddNewsModal(props){
    const {visible, data} = props;
    const rules = useRules()
    const [form] = Form.useForm();


    useEffect(() => {
        if(visible){
            form.setFieldsValue(data)
        }
    }, [visible,data]);

    const onCancel = ()=>{
        hideModalAction()
    }

    const onOk = ()=>{
        hideModalAction()
        form.validateFields().then(res=>{
            props.onOk(res)
        })
    }

    return <Modal
        title={"创建新闻"}
        className={"nms-perf-modal"}
        visible={visible}
        onCancel={onCancel}
        destroyOnClose={true}
        footer={
            <>
                <Button onClick={onCancel}>{'取消'}</Button>
                <Button onClick={onOk} type="primary">{"保存"}</Button>
            </>
        }
    >
        <Form
            form={form}
            labelCol={{span:6}}
            labelAlign={"right"}
            wrapperCol={{span:16}}
            autoComplete="off"
        >
            <Form.Item
                label={"新闻类型"}
                name="type"
                rules={[rules.name]}
                initialValue={"notice"}
            >
                <Select options={[{label: "公告", value: "notice"}, {label: "新闻", value: "news"}, {label: "活动", value: "activity"}]}/>
            </Form.Item>

            <Form.Item
                label={"标题"}
                name="title"
                rules={[{required: true}, rules.name]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label={"Url"}
                name="url"
                rules={[{required: true},]}
            >
                <Input/>
            </Form.Item>
        </Form>
    </Modal>
}

const mapStateToProps = (state) => {
    return {
        visible: state.modal.modalKey === "AddNewsModal",
        data: state.modal.modalData,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddNewsModal)