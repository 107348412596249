import './index.css'
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getHotPostApi, listPostApi, postStatisticsApi, postStatisticsCountApi} from "../../../apis/post";
import {Empty, Input, Pagination, Select, Spin, Badge} from "antd";
import {getServerImgPath} from "../../../utils/ImageUtil";
import {getPostType, useTypes} from "../../../utils/TypeUtil";
import {useNavigate} from "react-router-dom";

let preType = "all"
let preSearchText = ""

function ForumIndex(props) {
    const {userInfo} = props;
    const [hotPosts, setHotPosts] = useState([])
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [postTableData, setPostTableData] = useState({
        total: 0,
        rows: []
    })

    useEffect(()=>{
        const themeToggle = document.querySelector('.theme-toggle');
        const body = document.body;

        themeToggle.addEventListener('click', () => {
            body.classList.toggle('dark-theme');
            if (body.classList.contains('dark-theme')) {
                themeToggle.textContent = '☀️';
            } else {
                themeToggle.textContent = '🌓';
            }
        });

        getHotPostApi().then(res=>{
            if(res.code === 200){
                setHotPosts(res.data);
            }
        })



        onSearch(0, "")

    },[])



    const onSearch = (sectionId, searchText, page=1) => {
        preType = sectionId
        preSearchText = searchText

        setLoading(true);
        listPostApi({
            sectionId,
            searchText,
            page: page,
            pageSize: 10,
        }).then(res=>{
            if(res.code===200){
                setPostTableData(res.data)
            }

            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }



    return <div style={{background: "rgba(209,218,229,0.5)",  zIndex: 1}}>
        <div className="forum-container">
            <div className="forum-main-content">
                <Spin spinning={loading}>
                    <div className="forum-board">
                        <SearchComponent onSearch={onSearch}/>

                        <ul className="post-list" style={{padding: "0 20px"}}>
                            {postTableData.rows.length === 0 && <Empty/>}
                            {postTableData.rows.length !== 0 && postTableData.rows.map(post => {

                                return <Badge.Ribbon text={getPostType(post)} color="cyan" key={post.id}>
                                    <li className="post-item" id={post.id}>
                                        <img src={getServerImgPath(post.userInfo?.headUrl)} alt="用户头像"
                                             className="post-avatar"/>
                                        <a className="post-info" onClick={() => {
                                            navigate(`/forum/post/details/${post.id}`)
                                        }}>
                                            <div className="post-title">{post.title}</div>
                                            <div className="post-meta">
                                                <span>作者：{post.userInfo?.nickname}</span>
                                                <span>时间：{post.createAt}</span>
                                                <span>浏览：{post.view}</span>
                                                <span>点赞：{post.likes}</span>
                                            </div>
                                        </a>
                                    </li>
                                </Badge.Ribbon>
                            })}
                        </ul>

                        <div className="index-pagination">
                            <div className="pagination-info">共 {postTableData.total} 条帖子</div>
                            <div className="pagination-controls">
                                <Pagination size="small" current={postTableData?.page || 1}
                                            pageSize={postTableData?.pageSize || 10} total={postTableData?.total}
                                            onChange={(page) => {
                                                onSearch(preType, preSearchText, page)
                                            }}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>


            </div>

            <div className="sidebar">
                {userInfo?.username && <PostStatistics userId={userInfo?.id}/>}
                <div className="sidebar-widget">
                    <h3 className="widget-title">热门推荐</h3>
                    <ul className="recommended-list">
                        {hotPosts.length === 0 && <Empty/>}

                        {hotPosts.length !== 0 && hotPosts.map(post => {
                            return <li className="recommended-item" key={post.id}>
                                <a onClick={() => {
                                    navigate(`/forum/post/details/${post.id}`)
                                }} className="recommended-link">
                                    <img src={getServerImgPath(post.userInfo?.headUrl)} alt="用户头像"
                                         className="post-avatar"/>
                                    <div className="recommended-content">
                                        <div className="recommended-title">{post.title}</div>
                                        <div className="recommended-meta">作者：{post.userInfo?.nickname} |
                                            浏览：{post.view}</div>
                                    </div>
                                </a>
                            </li>
                        })}
                    </ul>


                </div>


            </div>
        </div>

        <button className="theme-toggle">🌓</button>

    </div>
}


export function PostStatistics(props) {
    const {userId} = props;

    const [data, setData] = useState({
        count: 0,
        view: 0,
        like: 0,
        star: 0,
    })

    useEffect(() => {
        postStatisticsApi(userId).then(res => {
            if (res.code === 200) {
                setData(res.data)
            }
        })

    }, [userId])


    return <div className="sidebar-widget">
        <h3 className="widget-title">我的统计</h3>
        <div className="user-stats">
            <div className="stat-item">
                <div className="stat-value">{data.count}</div>
                <div className="stat-label">发帖数</div>
            </div>
            <div className="stat-item">
                <div className="stat-value">{data.view}</div>
                <div className="stat-label">浏览量</div>
            </div>
            <div className="stat-item">
                <div className="stat-value">{data.like}</div>
                <div className="stat-label">获赞数</div>
            </div>
            <div className="stat-item">
                <div className="stat-value">{data.star}</div>
                <div className="stat-label">收藏数</div>
            </div>
        </div>
    </div>
}

const {Option} = Select;

const SearchComponent = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchScope, setSearchScope] = useState(0);
    const [sectionCount, setSectionCount] = useState({

    })
    const types = useTypes()

    useEffect(() => {
        postStatisticsCountApi().then(res=>{
            let sectionCount = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
            }
            if(res.code===200){
                for (const item of res.data) {
                    sectionCount[item.section_id] = item.count
                }
            }

            setSectionCount(sectionCount)
        })

    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleScopeChange = (value) => {
        setSearchScope(value);
    };

    const search = () => {
        if (props.onSearch) {
            props.onSearch(searchScope, searchQuery)
        }
    };

    function onChangeSection(section){
        setSearchScope(section)
        setSearchQuery("")

        if (props.onSearch) {
            props.onSearch(section, "")
        }
    }



    return (
        <>
            <div className={"board-header"}>
                <div style={{margin: 'auto'}}>
                    <Input.Search
                        className={"post-search"}
                        placeholder="搜索帖子..."
                        addonBefore={<Select
                            style={{width: 120}}
                            onChange={handleScopeChange}
                            value={searchScope}
                            options={[{label: "所有帖子", value: 0}, ...types.postType]}
                        />}
                        onSearch={search}
                        onChange={handleSearchChange}
                        value={searchQuery}
                    />
                </div>
            </div>

            <div>
                <div className="board-overview">
                    <div className="board-card" onClick={()=>{onChangeSection(1)}}>
                        <div className="board-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/>
                            </svg>
                        </div>
                        <div className="board-name">游戏讨论</div>
                        <div className="board-posts">帖子：{sectionCount["1"]}</div>
                    </div>
                    <div className="board-card" onClick={()=>{onChangeSection(2)}}>
                        <div className="board-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"/>
                            </svg>
                        </div>
                        <div className="board-name">MOD 制作</div>
                        <div className="board-posts">帖子：{sectionCount["2"]}</div>
                    </div>
                    <div className="board-card" onClick={()=>{onChangeSection(3)}}>
                        <div className="board-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
                            </svg>
                        </div>
                        <div className="board-name">攻略心得</div>
                        <div className="board-posts">帖子：{sectionCount["3"]}</div>
                    </div>
                    <div className="board-card" onClick={()=>{onChangeSection(4)}}>
                        <div className="board-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 9h-2V5h2v6zm0 4h-2v-2h2v2z"/>
                            </svg>
                        </div>
                        <div className="board-name">反馈建议</div>
                        <div className="board-posts">帖子：{sectionCount["4"]}</div>
                    </div>
                </div>

            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumIndex)