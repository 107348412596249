import './index.css'
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Empty, Input, Pagination, Select, Spin} from "antd";
import {listCommonProblemApi} from "../../../../apis/workOrder";
import {CommonProblemTops} from "../Manage";

let preType = "all"
let preSearchText = ""

function CommonProblem(props) {

    const [loading, setLoading] = useState(false)
    const [postTableData, setPostTableData] = useState({
        total: 0,
        rows: []
    })

    useEffect(()=>{
        onSearch("all", "")
    },[])

    const onSearch = (type, searchText, page=1) => {
        preType = type
        preSearchText = searchText

        setLoading(true);
        listCommonProblemApi({
            type,
            searchText,
            page: page,
            pageSize: 10,
        }).then(res=>{
            if(res.code===200){
                setPostTableData(res.data)
            }

            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }


    return <div style={{zIndex: 1}}>
        <div className="forum-container">
            <div className="forum-main-content">
                <Spin spinning={loading}>
                    <div className="forum-board">
                        <div className="board-header">
                            <SearchComponent onSearch={onSearch}/>
                        </div>
                        <ul className="post-list">
                            {postTableData.rows.length === 0 && <Empty />}
                            {postTableData.rows.length !== 0 && postTableData.rows.map((post, index) => {
                                return <li className="post-item" id={post.id}>
                                    <div className="post-info">
                                        <a href={post.url}
                                           className="post-title">{index+1} . {post.title}</a>
                                        <div className="post-meta">
                                            <span>{post.shortDesc}</span>
                                        </div>
                                    </div>
                                </li>
                            })}

                        </ul>

                        <div className="index-pagination">
                            <div className="pagination-info">共 {postTableData.total} 条问题 </div>
                            <div className="pagination-controls">
                                <Pagination size="small"  current={postTableData?.page || 1} pageSize={postTableData?.pageSize || 10} total={postTableData?.total}
                                    onChange={(page)=>{
                                        onSearch(preType, preSearchText, page)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>

            </div>

            <div className="sidebar">
                <CommonProblemTops/>
            </div>
        </div>


    </div>
}


const {Option} = Select;

const SearchComponent = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchScope, setSearchScope] = useState("all");

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleScopeChange = (value) => {
        setSearchScope(value);
    };

    const search = () => {
        if (props.onSearch) {
            props.onSearch(searchScope, searchQuery)
        }
    };

    return (
        <div style={{margin: 'auto'}}>
            <Input.Search
                className={"post-search"}
                placeholder="搜索问题..."
                addonBefore={<Select
                    style={{width: 120}}
                    onChange={handleScopeChange}
                    value={searchScope}
                >
                    <Option value={"all"}>所有问题</Option>
                    <Option value={"gameQuestion"}>游戏</Option>
                    <Option value={"announcement"}>账号</Option>
                    </Select>}

                    onSearch={search}
                    onChange={handleSearchChange}
                    value={searchQuery}

                />
        </div>
    );
};




const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonProblem)