import store from "../redux/store";


const roles = {
    modWorker: "modworker", // mod创作者
    admin: "admin", // mod创作者
    manage: "manage", // mod创作者
    workOrder: "workerOrder", // mod创作者
}

function getUserInfo(){
    return store.getState().systemInfo.userInfo;
}

function getUserRoles(){
    return store.getState().systemInfo.userInfo?.roles || [];
}




export function authIsModWorker(){
    return getUserRoles().includes(roles.modWorker)
}


/**
 * 超级管理员
 * @return {*}
 */
export function authIsAdmin(){
    return getUserRoles().includes(roles.admin)
}



/**
 * 管理员
 * @return {*}
 */
export function authIsManage(){
    return getUserRoles().includes(roles.manage)
}

export function authIsWorkOrder(){
    return getUserRoles().includes(roles.workOrder)
}