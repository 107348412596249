import ReactQuill, {Quill} from "react-quill";
import {useEffect} from "react";




// 自定义的图片处理模块
const ImageBlot = Quill.import('blots/embed');

class Image extends ImageBlot {
    static create(value) {
        let node = super.create();
        node.setAttribute('src', value);
        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}

Image.blotName = 'image';
Image.tagName = 'img';
Quill.register(Image);


const modules = {
    toolbar: {
        container: [
            [{ header: [1, 2,3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', ],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image'],
            ['clean']
        ],
        //如果不自定义上传下面的可以不要
        handlers: {
            image() {
                imageHandler.call(this, 111);
            },
        }
    },
}

let uploadImageFunc

function imageHandler(action){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
        const file = input.files[0];
        uploadImageFunc(file)
    }
}


export default function ForumQuill(props){
    uploadImageFunc = props.uploadImage;

    return <ReactQuill
        modules={modules}
        className="ql-editor"
        {...props}
        ref={(f)=>{
            if(props.refFun){
                props.refFun(f)
            }
        }}
    />
}