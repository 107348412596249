import request from "../utils/request";



export function listSeriesModApi(params){
    return request({
        url: `/mod/download/listSeries`,
        method: "get",
        params
    })
}



export function listAreaModApi(params){
    return request({
        url: `/mod/download/listMod`,
        method: "get",
        params
    })
}

export function listSelfModApi(params){
    return request({
        url: `/mod/listSelf`,
        method: "get",
        params
    })
}


export function requestModApi(data){
    return request({
        url: `/mod/requestMod`,
        method: "get",
        params:data
    })
}

