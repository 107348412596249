import "./index.css"
import {Button, Col, Form, Input, Row, Space} from "antd";
import {getMailCodeApi, registerApi} from "../../../apis/user";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

import {error_notice, success_notice} from "../../../utils/noticeUtils";
import MailCaptchaModal from "./MailCaptchaModal";
import {hideModalAction, showModalAction} from "../../../redux/action/modalAction";


let curCodeTime = 0;

export default function Register() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // 验证码冷却时间
    const [codeTime, setCodeTime] = useState(0);

    const navigate = useNavigate();

    const onLoginClick = ()=>{
        form.validateFields().then(res=>{
            setLoading(true);
            registerApi(res.username, res.email, res.password, res.code).then(res=>{
                if(res.msg === "Succeed"){
                    success_notice("注册成功!")
                    navigate("/forum/login")
                }

                setLoading(false);
            }).catch(e=>{
                setLoading(false);
            })

        })
    }

    const onGetMailCode = (email, captchaCode)=>{
        hideModalAction()
        curCodeTime = 60
        setCodeTime(60)

        getMailCodeApi(email, captchaCode).then(res=>{
            if(res.code === 200 && res.data === true){
                let interval = setInterval(()=>{
                    curCodeTime -= 1;
                    setCodeTime(curCodeTime)
                    if(curCodeTime < 0){
                        clearInterval(interval)
                    }

                }, 1000)

                success_notice("获取邮箱验证码成功，请注意查收！")
            }else {
                curCodeTime = 0
                setCodeTime(0)
                error_notice("获取邮箱验证码失败！")
            }
        }).catch(e=>{
            curCodeTime = 0
            setCodeTime(0)

            error_notice("获取邮箱验证码失败！")
        })
    }



    return <div className="login-page">
        <div className="background"></div>
        <Form
            form={form}
            labelCol={{span: 7}}
            labelAlign={"right"}
            wrapperCol={{span: 14}}
            autoComplete="off"
            size={"large"}
        >
            <div className="bg-container">
                <div className="card" id="card">
                    <div className="card-face card-front">
                        <div className="card-header">
                            <img src="/forum/static/images/icon.png" alt="PocketDestiny Logo"
                                 className="card-logo"/>
                            <h1 className="card-title">注册PocketDestiny账号</h1>
                        </div>
                        <form className="card-form" >
                            <Form.Item
                                name={"username"}
                                rules={[{required: true}, {pattern: /^[a-zA-Z0-9_\\.]{6,12}$/, message:"请输入6到12位字母_.或者数字"}]}
                                label={"用户名"}
                                labelAlign={"right"}
                                labelCol={{span: 6}}
                                wrapperCol={{span: 18}}
                            >
                                <Input size={"large"} placeholder="用户名" />
                            </Form.Item>

                            <Form.Item
                                name={"email"}
                                label={"邮箱"}
                                labelAlign={"right"}
                                labelCol={{span: 6}}
                                wrapperCol={{span: 18}}
                                rules={[
                                    {
                                        type: 'email',
                                        message: '请输入有效的邮箱地址！',
                                    },
                                    {
                                        required: true,
                                        message: '请输入邮箱地址！',
                                    },
                                ]}
                            >
                                <Input size={"large"} placeholder="邮箱"/>
                            </Form.Item>

                            <Row>
                                <Col span={6}></Col>
                                <Col >
                                    <Button type={"primary"}  style={{width: 120, marginBottom: 24}} disabled={codeTime > 0} onClick={
                                        ()=>{
                                            form.validateFields(["email"]).then(res=>{
                                                showModalAction("MailCaptchaModal", {email: form.getFieldValue("email")})
                                            })
                                        }
                                    }>{codeTime > 0? codeTime: "获取验证码"}</Button>
                                </Col>
                            </Row>

                            <Form.Item
                                labelCol={{span: 6}}
                                labelAlign={"right"}
                                wrapperCol={{span: 18}}
                                name={"code"}
                                label={"验证码"}
                                rules={[{required: true}]}
                            >
                                <Input size={"large"}  placeholder="邮箱验证码"/>
                            </Form.Item>



                            <Form.Item
                                name={"password"}
                                label={"密码"}
                                labelCol={{span: 6}}
                                labelAlign={"right"}
                                wrapperCol={{span: 18}}
                                rules={[{required: true}, {pattern: /^[a-zA-Z0-9_\\.]{6,12}$/, message:"请输入6到12位字母_.或者数字"}]}
                            >
                                <Input.Password size={"large"}  placeholder="密码"/>
                            </Form.Item>

                            <Button type="primary" size={"large"} onClick={onLoginClick} loading={loading} >立即注册</Button>
                        </form>

                        <div className="card-footer">
                            <p><a className="link" href={"/forum/login"} >已有账号, 立即登录</a></p>
                        </div>
                    </div>

                    <MailCaptchaModal onOk={onGetMailCode}/>

                </div>
            </div>

        </Form>


    </div>

}


