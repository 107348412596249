
import './index.css'
import {useEffect, useState} from "react";
import {listAreaModApi, listSeriesModApi} from "../../apis/mod";
import {Button, Divider, List, Tabs} from "antd";
import {getAppInfoApi} from "../../apis/app";



export default function Download(){
    const [series, setSeries] = useState([])
    const [appInfo, setAppInfo] = useState({})

    useEffect(()=>{
        getAppInfoApi().then(res=>{
            if(res.code === 200){
                setAppInfo(res.data)
            }
        })
    }, [])



    useEffect(()=>{
        queryMod({
            page:1,
            pageSize: 100,
        })

    }, [])

    const queryMod = (params) =>{
        let queryApi = listSeriesModApi;

        listSeriesModApi(params).then(res=>{
            if(res.code === 200){
                setSeries(res.data.records)
            }
        })
    }



    return <div className="download-page">
        <div className="background"></div>
        <div className="content">
            <div className="download-section">
                <h1>下载 PocketDestiny</h1>
                <div className="game-info">
                    <div style={{padding: "0 50px"}}>
                        <img src="/forum/static/images/icon.png"
                             alt="PocketDestiny Logo" className="game-logo"/>
                    </div>

                    <div className="game-details">
                        <p><strong>游戏版本:</strong> {appInfo?.version}</p>
                        <p><strong>更新日期:</strong> {appInfo?.createAt}</p>
                        <p>{appInfo?.remark}</p>
                    </div>
                </div>
                <div className="download-options">
                    <a href={appInfo?.downloadUrl} className="download-btn" >下载</a>
                </div>

                <Divider style={{margin: "6px 0"}}/>

                <Tabs >
                    {series.map((item, index) => {
                        return  <Tabs.TabPane tab={item.name} key={item.name}>
                            <div style={{padding: "1rem"}}>
                                <div className="game-details">
                                    <p> {item.remark}</p>
                                </div>
                                <div className="download-options">
                                    <a href={item.host+item?.download} className="download-btn">下载</a>
                                </div>

                                <Divider style={{margin: "6px 0"}}/>
                                <Mods seriesData={item}/>
                            </div>
                        </Tabs.TabPane>
                    })}
                </Tabs>
            </div>

        </div>
    </div>
}


function Mods(props){
    const {seriesData} = props;
    const [data, setData] = useState([])

    useEffect(() => {
        listAreaModApi({
            page:1,
            pageSize: 100,
        }).then(res=>{
            if(res.code===200){
                let list = []
                for (let record of res.data.records) {
                    if(record.category === seriesData?.category){
                        list.push(record)
                    }
                }

                list = list.filter(mod=>mod.status === 1 || mod.status === 2)

                setData(list)
            }
        })
    }, []);


    return <List
        itemLayout="horizontal"
        size={"small"}
        dataSource={data}
        renderItem={(item) => (
            <List.Item key={item.id} className={"news-item"}>
                <h3>{item.name}</h3>
                {item.status === 1 ? <a href={item.host+item?.download}><Button>下载</Button></a>: <span>待开放</span>}

            </List.Item>
        )}/>
}