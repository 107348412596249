import { Divider, Typography } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;

const blockContent = `一、用户信息与账号
账号归属：
游戏账号及其内的游戏角色、虚拟道具等的所有权归游戏账号及其内的游戏角色、虚拟道具等的所有权归PocketDestiny所有。
玩家仅获得账号的使用权，不得进行买卖、转让等行为。
二、用户行为规范
遵守法律法规：
玩家必须为自己游戏账号下的一切行为负责，包括发表的任何内容以及由此产生的任何后果。
不得利用游戏进行违法、违规或有害的行为。
禁止行为：
不得进行任何侵犯游戏知识产权的行为
不得从事违法违规行为，如危害国家安全、泄露国家秘密、宣扬恐怖主义
禁止发布、传播违法、违规或不良信息。
禁止利用游戏进行赌博、诈骗等违法行为。
用户交互：
玩家在游戏中与其他玩家交互时，应遵守游戏规则和社交礼仪。
不得通过游戏收集、使用或泄露其他玩家的个人信息。
三、游戏服务与虚拟道具
游戏服务：
PocketDestiny向玩家提供与游戏相关的各项在线运营服务。
玩家有权享受PocketDestiny提供的游戏服务，并应遵守游戏规则。
虚拟道具：
游戏中的虚拟道具(包括游戏币、皮肤)是PocketDestiny提供的服务的一部分。
玩家可以通过购买、任务等方式获得虚拟道具，但仅获得其使用权。
虚拟道具的获取、使用等应遵循PocketDestiny的相关规定。
四、隐私保护与信息安全
信息收集：
注册信息: PocketDestiny会收集邮箱等基本信息、这些信息将用于账号验证、游戏登录、密码找回等目的
信息安全：
PocketDestiny会采取必要措施保护玩家信息安全，防止信息泄露或被滥用。
信息使用：
PocketDestiny会按照相关法律法规和隐私政策的规定使用玩家信息。
未经玩家同意，游戏不会将玩家信息提供给第三方。
五、作弊处理
对于使用第三方脚本、恶意利用漏洞行为，系统将根据具体情况进行账号封禁数天至数年，甚至永久封禁。
六、其他条款
免责条款：
我们可能会因不可抗力因素、系统维护、升级或不可抗力因素导致游戏服务中断。在此情况下，我们不对因服务中断给您带来的任何损失承担责任。
游戏不提供任何内购渠道，玩家与任何个人或集体产生的交易行为概不负责。
`;

export default function UserAgreement(props){

    return <div style={{padding: "15px 15px"}}>
        <Typography>
            <Title>PocketDestiny用户协议</Title>
            <Paragraph>
                <pre>{blockContent}</pre>
            </Paragraph>
        </Typography>
    </div>
}