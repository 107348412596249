import React from 'react';
import ReactDOM from 'react-dom/client';
import './redefine.less'
import 'antd/dist/antd.less'
import 'moment/locale/zh-cn';
import App from './App';


let root = null;

function render(props){

    root = ReactDOM.createRoot(document.querySelector(`#app`));


    root.render(
        <App/>
    );
}

render({});



