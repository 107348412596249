import moment from "moment";

const FORMAT = "YYYY-MM-DD HH:mm:ss";


export function parseTimeStr(timeStr, format = FORMAT){
    return moment(timeStr, format)
}


export function dateFormat(timeMount, format = FORMAT){
    return timeMount.format(format);
}

export function dateUtcFormat(time, format = FORMAT) {
    return moment(time).format(format);
}

export function formatToDate(time, format = FORMAT){
    return moment(time, format).valueOf();
}
